@import "//cdnjs.cloudflare.com/ajax/libs/lightgallery/2.7.0/css/lightgallery.min.css";
@import "//cdnjs.cloudflare.com/ajax/libs/lightgallery/2.7.0/css/lg-zoom.min.css";
@import "//cdnjs.cloudflare.com/ajax/libs/lightgallery/2.7.0/css/lg-thumbnail.min.css";
// https://cdnjs.com/libraries/lightgallery



.lg-react-element {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.gallery-container {
  list-style: none;
  display: flex;
  flex-wrap: wrap;

  li {
    height: 40vh;
    flex-grow: 1;
    cursor: pointer;
    padding: 0px 2px 4px 4px;
  }

  
  li:last-child {
    // There's no science in using "10" here. In all my testing, this delivered the best results.
    flex-grow: 10;
  }
  li:hover img {
    /*filter: grayscale(0%);*/
  }
  img {
    /*filter: grayscale(100%);*/
    max-height: 100%;
    min-width: 100%;
    object-fit: cover;
    vertical-align: bottom;
    border-radius: 4px;
    transition: 0.5s;
  }
  
  // ADVANCED
  
  // Portrait
  
  @media (max-aspect-ratio: 1/1) {
    li {
      height: 30vh;
    }
  }
  
  // Short screens
  
  @media (max-height: 480px) {
    li {
      height: 80vh;
    }
  }
  
  // Smaller screens in portrait
  
  @media (max-aspect-ratio: 1/1) and (max-width: 480px) {
    ul {
      flex-direction: row;
    }
  
    li {
      height: auto;
      width: 100%;
    }
    img {
      width: 100%;
      max-height: 75vh;
      min-width: 0;
    }
  }

}