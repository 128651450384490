$mobile : "600px";

.home-slider {
  width: 100%;
  height: 100%;
  right: 0;
  position: absolute;
  text-align: center;
  top:0px;
  z-index: 0;
  .owl-carousel, 
  .owl-stage-outer, 
  .owl-stage, 
  .owl-item  {
    height: 100%;
  }
  .item {
    width: 100%;
    height: 100%;
    position: relative;
    background: rgb(255,255,255);
    background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(0,0,0,1) 100%);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .item img {
    position: absolute;
    width: fit-content !important;
    height: 100%;
    object-fit: cover;
  }
  @media only screen and (max-width: $mobile) {

    .item img  {
      width: 100% !important;
      height: 100% !important;

    }
  
  }
  .owl-nav {
    display: none;
  }
  .owl-dots {
    position: absolute;
    bottom: 0px;
    width: 100%;
    z-index: 9;
    color: #000;
  }
  .owl-dot {
    width: 10px;
    height: 10px;
    background: #cf0000;
    display: inline-block;
    border-radius: 10px;
    margin: 0 5px;
    &.active {
      background: #fff;
    }
    &:hover {
      background: #fff;
    }
  }
}


/*mouse*/

.arrows {
  width: 60px;
  height: 72px;
  position: absolute;
  left: 50%;
  margin-left: -30px;
  bottom: 40px;
}

.arrows path {
  stroke: #fff;
  fill: transparent;
  stroke-width: 1px;  
  animation: arrow 2s infinite;
  -webkit-animation: arrow 2s infinite; 
}

@keyframes arrow
{
0% {opacity:0}
40% {opacity:1}
80% {opacity:0}
100% {opacity:0}
}

@-webkit-keyframes arrow /*Safari and Chrome*/
{
0% {opacity:0}
40% {opacity:1}
80% {opacity:0}
100% {opacity:0}
}

.arrows path.a1 {
  animation-delay:-1s;
  -webkit-animation-delay:-1s; /* Safari 和 Chrome */
}

.arrows path.a2 {
  animation-delay:-0.5s;
  -webkit-animation-delay:-0.5s; /* Safari 和 Chrome */
}

.arrows path.a3 { 
  animation-delay:0s;
  -webkit-animation-delay:0s; /* Safari 和 Chrome */
}

